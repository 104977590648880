export const rootFolderChain = [
  { id: "root_folder", name: "Root", isDir: true, children: [] },
]

export const getRootFolderChain = (nameOfRoot = 'Root') => {
  let rootData = [
    { id: "root_folder", name: nameOfRoot, isDir: true, children: [] },
  ]
  return rootData
}

