import { GET_CURRENT_USER, USER_IS_ACTIVE } from "../constants/actionTypes";

const initialState = { currentUser: {}, isUserActive: false }

const userReducers = (state = initialState, action) => {
    switch (action.type) {
        case GET_CURRENT_USER:
            return { ...state, currentUser: action.payload, isUserActive: true }
        case USER_IS_ACTIVE:
            return { ...state, isUserActive: action.payload }
        default:
            return state;
    }
};

export default userReducers;