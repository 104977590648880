import React from "react";
import { Folder2Open } from "react-bootstrap-icons";
import Style from "./FileExplorer.module.css";

const EmptyFolderView = ({ userType }) => {
  return (
    <div className={Style.emptyFolderContainer}>
      <Folder2Open size={30} />
      <label className="my-2 fs-5">Nothing to show</label>
      {userType === "Admin" && (
        <label className="fs-6">
          Click on the 'Upload files' button to add files
        </label>
      )}
    </div>
  );
};

export default EmptyFolderView;
