import React, { useEffect, useMemo } from "react";
import { Folder, Folder2Open } from "react-bootstrap-icons";
import { useDropzone } from "react-dropzone";
import Style from "./DragAndDrop.module.css";
import FilesTable from "./FilesTable";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setUploadedFilesList } from "../../actions/uploadedFilesListActions";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "var(--gray-100)",
  borderStyle: "dashed",
  backgroundColor: "var(--gray-200)",
  color: "var(--gray-300)",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "var(--blue-500)",
};

const acceptStyle = {
  borderColor: "var(--blue-500)",
  color: "var(--blue-500)",
};

function DragAndDrop() {
  const uploadedFilesList = useSelector((state) => state.fileListUploaded);
  const dispatch = useDispatch();
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
  } = useDropzone();

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
    }),
    [isFocused, isDragAccept]
  );

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      // console.log(acceptedFiles);
      toast.success(acceptedFiles.length + " new file(s) has been added.");
      const updatedFilesWithId = acceptedFiles.map((file) => ({
        fileId: "id" + Math.random().toString(16).slice(2),
        file, // same as file: file,
      }));
      dispatch(
        setUploadedFilesList([...uploadedFilesList, ...updatedFilesWithId])
      );
    }
  }, [acceptedFiles]);

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {isDragAccept ? (
          <Folder2Open size={50} className={Style.folderActive} />
        ) : (
          <Folder size={50} />
        )}
        <p>Drag and drop files here, or click to select files</p>
      </div>
      <div>
        <FilesTable uploadedFilesList={uploadedFilesList} />
      </div>
    </div>
  );
}

export default DragAndDrop;
