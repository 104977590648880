import { SET_FILE_UPLOAD_PREFIX } from "../constants/actionTypes";

const initialState = ''

const uploadFilesReducers = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILE_UPLOAD_PREFIX:
            return action.payload
        default:
            return state;
    }
};

export default uploadFilesReducers;