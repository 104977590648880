import React, { useEffect, useState } from "react";
import { CloudArrowUp, XLg } from "react-bootstrap-icons";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const DownloadStartedModal = ({ show, setShow }) => {
  return (
    <div>
      <Modal isOpen={show} size="md">
        <ModalHeader>Preparing Files For Download</ModalHeader>
        <ModalBody>
          <p className="text-center text-muted mb-0">
            Your download will begin shortly. This may take a few moments...
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setShow(false)}>
            <XLg className="mb-1" /> Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DownloadStartedModal;
