import './App.css';
import FileExplorer from './components/FileExplorer/FileExplorer';

function App() {
  return (
    <div>
      <FileExplorer />
    </div>
  );
}

export default App;
