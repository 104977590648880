export const getPrefixWithId = (prefix) => {
    const currentProjectId = localStorage.getItem("projectId");
    if (prefix.split('/')[0] === currentProjectId)
        return prefix
    else
        return currentProjectId + "/" + (prefix === "/" ? "" : prefix);
};

export const removeCurrentUserIdFromKey = (prefix) => {
    const currentProjectId = localStorage.getItem("projectId");
    let prefixArr = prefix.split('/')
    if (prefixArr[0] === currentProjectId) {
        prefixArr.shift();
        return prefixArr.join("/");
    }
    else
        return prefix;
};