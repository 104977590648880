import { ACTIVATE_LOADER, DEACTIVATE_LOADER } from "../constants/actionTypes";

export const startLoader = () => {
    return {
        type: ACTIVATE_LOADER
    };
};
export const stopLoader = () => {
    return {
        type: DEACTIVATE_LOADER
    };
};