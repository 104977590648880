import React, { useEffect, useState } from "react";
import { ChonkyActions, FileBrowser, FileList, FileNavbar } from "chonky";
import { CustomIcon } from "./CustomIcon";
import Style from "./FileExplorer.module.css";
import { getRootFolderChain } from "./baseData";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Spinners from "../Spinners/Spinners";
import { s3, bucketName } from "../../constants/aws-s3";
import { startLoader } from "../../actions/loaderActions";
import { useDispatch } from "react-redux";
import { getPrefixWithId, removeCurrentUserIdFromKey } from "./commonFunctions";

export default function MoveFolderModal({
  showMoveFolder,
  setShowMoveFolder,
  moveFolderModalProps,
  parentFolderPrefix,
  deleteAwsObject,
  getAllObjectsInFolderAndSubFolder,
  getAwsHeaderObject,
  fileExplorerListView,
  projectDetails,
}) {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [folderChain, setFolderChain] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [prefix, setPrefix] = useState("/");

  useEffect(() => {
    if (showMoveFolder) {
      setSpinner(true);
      setDefaultFileAndFolder();
    }
  }, [showMoveFolder]);

  const myFileActions = [
    ChonkyActions.UploadFiles,
    ChonkyActions.DownloadFiles,
    ChonkyActions.CreateFolder,
    ChonkyActions.DeleteFiles,
  ];
  const actionsToDisable = [
    ChonkyActions.OpenSelection.id,
    ChonkyActions.SortFilesBySize.id,
    ChonkyActions.SortFilesByDate.id,
    ChonkyActions.ToggleHiddenFiles.id,
  ];
  const handleFileAction = (actions) => {
    if (
      actions.id === "mouse_click_file" &&
      actions.payload.clickType === "double"
    ) {
      onFolderDoubleCLick(actions.payload.file);
    } else if (actions.id === "open_files") {
      goToOpenFiles(actions.payload.targetFile);
    }
  };
  const setDefaultFileAndFolder = () => {
    setPrefix("/");
    setSpinner(true);
    getAwsData("/");
    setFolderChain(getRootFolderChain(projectDetails._name));
  };
  const onFolderDoubleCLick = (file) => {
    const listOfSelectedKeys = getSelectedKeys();
    if (listOfSelectedKeys.includes(file.id)) {
      return;
    }
    if (file.isDir) {
      setPrefix(file.id);
      setSpinner(true);
      getAwsData(file.id);
      createFolderChain(file);
    }
  };
  const createFolderChain = (file) => {
    setFolderChain([...folderChain, file]);
  };
  const goToOpenFiles = (targetFile) => {
    if (!targetFile.isDir) {
      return;
    }
    if (targetFile.id === "root_folder") {
      setDefaultFileAndFolder();
    } else {
      const listOfSelectedKeys = getSelectedKeys();
      if (listOfSelectedKeys.includes(targetFile.id)) {
        return;
      }
      setPrefix(targetFile.id);
      setSpinner(true);
      getAwsData(targetFile.id);
      let positionIndex = null;
      folderChain.forEach((file, index) => {
        if (file.id === targetFile.id) {
          positionIndex = index;
        }
      });
      if (positionIndex !== null) {
        let folderChainCopy = [...folderChain];
        folderChainCopy.splice(positionIndex + 1);
        setFolderChain(folderChainCopy);
      }
    }
  };
  const validateIfTargetIsSame = () => {
    if (
      parentFolderPrefix === folderChain[folderChain.length - 1].id ||
      (folderChain[folderChain.length - 1].id === "root_folder" &&
        parentFolderPrefix === "/")
    ) {
      toast.error("Cannot move to same folder!");
      return true;
    }
    return false;
  };

  const onSubmit = () => {
    if (validateIfTargetIsSame()) {
      return;
    }
    Swal.fire({
      title: "Are you sure?",
      text: `You want to move selected files to '${
        folderChain[folderChain.length - 1].name
      }'`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--blue-500)",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(startLoader());
        moveAllFiles();
      }
    });
  };

  const closeModal = () => {
    setShowMoveFolder(false);
  };

  function getAwsMetaDataNameForFiles(Key, headerObjectDataList) {
    let filteredData = headerObjectDataList.filter(
      (item) => item.Metadata.key === Key
    );
    return filteredData.length === 0 ? "" : filteredData[0].Metadata?.name;
  }

  function getAwsMetaDataNameForFolders(prefix, headerObjectDataList) {
    let filteredData = headerObjectDataList.filter(
      (item) => item.Metadata.key === prefix
    );
    return filteredData.length === 0 ? "" : filteredData[0].Metadata?.name;
  }

  function checkIfFile(str) {
    let isEmpty = str.split("/").pop();
    return isEmpty === "" ? false : true;
  }

  const getAwsData = (prefix) => {
    const listOfSelectedKeys = getSelectedKeys();
    return s3
      .listObjectsV2({
        Bucket: bucketName,
        Delimiter: "/",
        // Prefix: prefix === "/" ? "" : prefix,
        Prefix: getPrefixWithId(prefix),
      })
      .promise()
      .then(async (response) => {
        console.log(response);
        const chonkyFiles = [];
        const s3Objects = response.Contents;
        const s3Prefixes = response.CommonPrefixes;

        const s3ObjectsKeys = s3Objects.map((item) => item.Key);
        const s3PrefixesKeys = s3Prefixes.map((item) => item.Prefix);
        const s3ObjectsHeadersList = await getAwsHeaderObject([
          ...s3PrefixesKeys,
          ...s3ObjectsKeys,
        ]);

        if (s3Objects) {
          let s3ObjectsFiltered = s3Objects.filter((file) =>
            checkIfFile(file.Key)
          );
          chonkyFiles.push(
            ...s3ObjectsFiltered.map((object) => {
              return {
                // add user Id
                id: getPrefixWithId(object.Key),
                name: getAwsMetaDataNameForFiles(
                  object.Key,
                  s3ObjectsHeadersList
                ),
                modDate: object.LastModified,
                size: object.Size,
                ...(listOfSelectedKeys.includes(object.Key) && {
                  color: "var(--gray-300)",
                }),
              };
            })
          );
        }

        if (s3Prefixes) {
          chonkyFiles.push(
            ...s3Prefixes.map((prefix) => ({
              // add user Id
              id: getPrefixWithId(prefix.Prefix),
              name: getAwsMetaDataNameForFolders(
                prefix.Prefix,
                s3ObjectsHeadersList
              ),
              isDir: true,
              color: listOfSelectedKeys.includes(prefix.Prefix)
                ? "var(--gray-300)"
                : "var(--yellow-500)",
            }))
          );
        }
        console.log(chonkyFiles);
        setSpinner(false);
        setFiles(chonkyFiles);
      })
      .catch((err) => {
        setSpinner(false);
        toast.error("Cannot get data");
        console.log("get aws data err", err);
      });
  };

  const getSelectedKeys = () => {
    return moveFolderModalProps.length === 0
      ? []
      : moveFolderModalProps?.actions?.state?.selectedFiles?.map(
          (item) => item.id
        );
  };

  const moveAllFiles = async () => {
    try {
      const allObjects = await getAllObjectsInFolderAndSubFolder(
        moveFolderModalProps.actions
      );

      const objectKeys = allObjects.map((item) => item.Key);
      const s3ObjectsHeadersList = await getAwsHeaderObject(objectKeys);

      const keyToName = (key) => {
        return s3ObjectsHeadersList.filter(
          (item) => item.Metadata.key === key
        )[0].Metadata.name;
      };

      const listOfKeysToCopy = allObjects.map((item) => ({
        sourceKey: item.Key,
        key: item.Key.replace(
          parentFolderPrefix === "/" ? "" : parentFolderPrefix,
          ""
        ),
        name: keyToName(item.Key),
      }));

      const copyToFolder =
        folderChain[folderChain.length - 1].id === "root_folder"
          ? ""
          : folderChain[folderChain.length - 1].id;

      await copyAwsObjects(listOfKeysToCopy, copyToFolder);
      const deletedCheck = deleteAwsObject(allObjects, true);
      if (deletedCheck) {
        closeModal();
      }
    } catch (error) {
      console.error("Move operation failed:", error);
      toast.error("Move operation failed");
    }
  };

  const copyAwsObjects = (listOfKeysToCopy, copyToFolder) => {
    console.log(listOfKeysToCopy);
    console.log(copyToFolder);
    const copyObjects = async (item) => {
      const params = {
        Key: getPrefixWithId(
          copyToFolder + removeCurrentUserIdFromKey(item.key)
        ), // add user Id
        Bucket: bucketName,
        CopySource: bucketName + "/" + item.sourceKey,
        MetadataDirective: "REPLACE",
        Metadata: {
          name: item.name,
          key: getPrefixWithId(
            copyToFolder + removeCurrentUserIdFromKey(item.key)
          ),
        },
      };

      return await s3.copyObject(params).promise();
    };
    const objectPromiseList = [];
    listOfKeysToCopy.forEach((item) => {
      const data = copyObjects(item);
      objectPromiseList.push(data);
    });
    return Promise.all(objectPromiseList);
  };

  const viewProps = {
    ...(fileExplorerListView && {
      defaultFileViewActionId: ChonkyActions.EnableListView.id,
    }),
  };

  return (
    <Modal isOpen={showMoveFolder} size="lg">
      <ModalHeader>Select Target Folder</ModalHeader>
      <ModalBody>
        <div className={Style.moveFolderContainer}>
          <FileBrowser
            files={files}
            folderChain={folderChain}
            onFileAction={handleFileAction}
            fileActions={myFileActions}
            disableDefaultFileActions={actionsToDisable}
            iconComponent={CustomIcon}
            disableDragAndDrop={true}
            disableSelection={true}
            {...viewProps}
          >
            <FileNavbar />
            <div className={Style.spinnerContainer}>
              {spinner ? (
                <Spinners />
              ) : (
                <div className={"fileListContainer"}>
                  <FileList />
                </div>
              )}
            </div>
          </FileBrowser>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onSubmit}>
          Move here
        </Button>
        <Button color="secondary" onClick={() => closeModal()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
