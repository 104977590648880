import { ACTIVATE_LOADER, DEACTIVATE_LOADER } from "../constants/actionTypes";

const initialState = {
    isLoaderActive: false
};

const loaderReducers = (state = initialState, action) => {
    switch (action.type) {
        case ACTIVATE_LOADER:
            return {
                ...state,
                isLoaderActive: true
            };
        case DEACTIVATE_LOADER:
            return {
                ...state,
                isLoaderActive: false
            };
        default:
            return state;
    }
};

export default loaderReducers;