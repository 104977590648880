import { GET_PROJECT_DETAILS } from "../constants/actionTypes";

const initialState = {}

const projectsReducers = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROJECT_DETAILS:
            return { ...action.payload }
        default:
            return state;
    }
};

export default projectsReducers;