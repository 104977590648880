import React from "react";
import { FolderSymlinkFill, PencilSquare } from "react-bootstrap-icons";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import Style from "./CustomIcon.module.css";

export const CustomIcon = React.memo((props) => {
  if (props.icon === "move") {
    return <FolderSymlinkFill className={Style.iconMain} />;
  } else if (props.icon === "rename") {
    return <PencilSquare className={Style.iconMain} />;
  }
  return <ChonkyIconFA {...props} />;
});
