import React, { useEffect, useState } from "react";
import Style from "./DragAndDrop.module.css";
import {
  CheckCircleFill,
  FileEarmark,
  XCircleFill,
} from "react-bootstrap-icons";
import { converter } from "../../utils/sizeConverter";
import { Progress } from "reactstrap";
import { useSelector } from "react-redux";
import { s3, bucketName } from "../../constants/aws-s3";
import { getPrefixWithId } from "../FileExplorer/commonFunctions";
import {
  removeCounterForUploadedFiles,
  setCounterForUploadedFiles,
} from "../../actions/uploadedFilesCountersActions";
import { useDispatch } from "react-redux";

const EachFile = ({ file }) => {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const prefixPath = useSelector((state) => state.fileUpload);

  useEffect(() => {
    if (file) {
      dispatch(setCounterForUploadedFiles());
      handleFileUpload(file.file);
    }
  }, [file]);

  const handleFileUpload = async (file) => {
    onUploadFiles(file).on("httpUploadProgress", function (progress) {
      let progressPercentage = Math.round(
        (progress.loaded / progress.total) * 100
      );
      if (progressPercentage < 100) {
        setProgress(progressPercentage);
      } else if (progressPercentage === 100) {
        setProgress(progressPercentage);
      }
    });
  };

  const onUploadFiles = (file) => {
    let filePath = file.path;
    let firstChar = file.path.slice(0, 1);
    if (firstChar === "/") {
      filePath = filePath.substring(1);
    }

    const finalKey = `${prefixPath === "/" ? "" : prefixPath}${filePath}`;
    const customMetadata = {
      name: file.name,
      key: getPrefixWithId(finalKey),
    };
    const params = {
      Bucket: bucketName,
      Key: getPrefixWithId(finalKey), // add user Id
      Body: file,
      ContentType: file.type,
      Metadata: customMetadata,
    };

    return s3.upload(params, function (err, data) {
      dispatch(removeCounterForUploadedFiles());
      if (err) {
        console.log("There was an error uploading your file: ", err);
        setSuccess(false);
        setFailed(true);
        return false;
      }
      console.log("Successfully uploaded file.", data);
      setFailed(false);
      setSuccess(true);
      createAwsObjects(finalKey);
      return true;
    });
  };

  function createPaths(keysArr, finalArr, position) {
    if (keysArr.length === position) {
      return finalArr;
    } else {
      let arr = keysArr.slice(0, position + 1);
      let path = arr.join("/") + "/";
      return createPaths(keysArr, [...finalArr, path], position + 1);
    }
  }

  const createAwsObjects = (key) => {
    const folderNameArray = key.split("/");
    folderNameArray.pop();
    let pathsList = createPaths(folderNameArray, [], 0);
    pathsList.forEach(async (element) => {
      try {
        let elementArray = element.split("/");
        let nameOfFolder = elementArray[elementArray.length - 2];
        await s3
          .putObject({
            // add user Id
            Key: `${getPrefixWithId(element)}`, // This should create an empty object for each uploaded file, which helps in delete
            Bucket: bucketName,
            Metadata: {
              name: nameOfFolder,
              key: `${getPrefixWithId(element)}`,
            },
          })
          .promise();
      } catch (err) {
        console.log("Error creating object for uploaded files", err);
      }
    });
  };

  return (
    <li key={file.fileId} className={`${Style.filesTableLi}`}>
      <div className="d-flex">
        <div className="my-auto">
          <FileEarmark size={25} className="me-3" />
        </div>
        <div>
          <label>{file.file.name}</label>
          <p className={`mb-0 ${Style.fileSizeText}`}>
            {converter(file.file.size)}
          </p>
        </div>
      </div>
      {file.progress !== 0 && !success && !failed ? (
        <div className="mt-3">
          <Progress animated color="primary" striped value={progress} />
        </div>
      ) : null}
      {failed && (
        <div>
          <XCircleFill size={20} className={`${Style.crossRed} me-2`} />
          <label className={`${Style.adjustLabelSuccessFileUpload} my-auto`}>
            File Upload Failed!
          </label>
        </div>
      )}
      {success && (
        <div>
          <CheckCircleFill size={20} className={`${Style.checkGreen} me-2`} />
          <label className={`${Style.adjustLabelSuccessFileUpload} my-auto`}>
            File Uploaded Successfully!
          </label>
        </div>
      )}
    </li>
  );
};

export default EachFile;
