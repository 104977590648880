import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormFeedback,
  FormText,
  InputGroupText,
  InputGroup,
} from "reactstrap";

const FolderUpdateModal = ({
  folderUpdateModal,
  setFolderUpdateModal,
  createFolderSubmit,
  renameFolderSubmit,
}) => {
  const [name, setName] = useState("");
  const [extension, setExtension] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    if (
      folderUpdateModal.type === "renameFolder" &&
      folderUpdateModal.existingName.length > 0
    ) {
      if (folderUpdateModal.isFile) {
        setName(getFilenameAndExtension(folderUpdateModal.existingName)[0]);
      } else {
        setName(folderUpdateModal.existingName);
      }
      setExtension(getFilenameAndExtension(folderUpdateModal.existingName)[1]);
    } else {
      setName("");
    }
  }, [folderUpdateModal]);

  const fieldValidation = (enteredName) => {
    if (enteredName.length === 0) {
      setIsInvalid(true);
      return true;
    } else {
      setIsInvalid(false);
    }
  };
  const nameValidation = (enteredName) => {
    if (enteredName.includes("/")) {
      toast.error("Name cannot contain '/' ");
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = () => {
    if (fieldValidation(name)) {
      return;
    }
    if (nameValidation(name)) {
      return;
    }
    if (folderUpdateModal.type === "createFolder") {
      createFolderSubmit(name);
    } else if (folderUpdateModal.type === "renameFolder") {
      if (folderUpdateModal.isFile) {
        renameFolderSubmit(name + "." + extension);
      } else {
        renameFolderSubmit(name);
      }
    }
    closeModal();
  };
  const closeModal = () => {
    setFolderUpdateModal({ ...folderUpdateModal, show: false });
    setName("");
    setIsInvalid(false);
  };

  function getFilenameAndExtension(fileName) {
    var fileNameExtension = fileName.replace(/^.*[\\\/]/, "");
    var filename = fileNameExtension.substring(
      0,
      fileNameExtension.lastIndexOf(".")
    );
    var ext = fileNameExtension.split(".").pop();

    return [filename, ext];
  }

  return (
    <>
      <Modal isOpen={folderUpdateModal.show} size="md" autoFocus={false}>
        <ModalHeader>{folderUpdateModal.title}</ModalHeader>
        <ModalBody>
          {folderUpdateModal.isFile ? (
            <>
              <InputGroup className="mb-2">
                <Input
                  placeholder="Enter file name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    fieldValidation(e.target.value);
                  }}
                  invalid={isInvalid}
                  autoFocus={true}
                />
                <InputGroupText>.{extension}</InputGroupText>
                <FormFeedback>File name can't be blank!</FormFeedback>
              </InputGroup>

              <FormText>Note: File extension is not editable.</FormText>
            </>
          ) : (
            <>
              <Input
                placeholder="Enter folder name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  fieldValidation(e.target.value);
                }}
                invalid={isInvalid}
                autoFocus={true}
                className="mb-2"
              />
              <FormFeedback>Folder name can't be blank!</FormFeedback>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onSubmit}>
            {folderUpdateModal.type === "createFolder" ? "Add" : "Update"}
          </Button>
          <Button color="secondary" onClick={() => closeModal()}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default FolderUpdateModal;
