import { combineReducers } from "redux";
import loaderReducers from "./loaderReducers";
import fileExplorerReducers from "./fileExplorerReducers";
import uploadFilesReducers from "./uploadFilesReducers";
import uploadedFilesListReducers from "./uploadedFilesListReducers";
import userReducers from "./userReducers";
import uploadedFilesCountersReducers from './uploadedFilesCountersReducers'
import projectsReducers from './projectsReducers'
import errorUpdateReducer from './errorUpdateReducer'

const rootReducer = combineReducers({
    loader: loaderReducers,
    fileExplorer: fileExplorerReducers,
    fileUpload: uploadFilesReducers,
    fileListUploaded: uploadedFilesListReducers,
    users: userReducers,
    uploadedFilesCounter: uploadedFilesCountersReducers,
    projectDetails: projectsReducers,
    errorsUpdate: errorUpdateReducer
});

export default rootReducer;