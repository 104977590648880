import { SET_UPLOADED_FILE_LIST } from "../constants/actionTypes";

const initialState = []

const uploadedFilesListReducers = (state = initialState, action) => {
    switch (action.type) {
        case SET_UPLOADED_FILE_LIST:
            return [...action.payload]
        default:
            return state;
    }
};

export default uploadedFilesListReducers;