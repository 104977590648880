import React from "react";
import Style from "./Loader.module.css";
import { useSelector } from "react-redux";

const Loader = ({ children }) => {
  const isActive = useSelector((state) => state.loader.isLoaderActive);
  return (
    <div>
      <div className={isActive ? Style.loading : ""}></div>
      {children}
    </div>
  );
};

export default Loader;
