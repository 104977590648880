import axios from "axios";

const downloadURL = 'https://api.bidsimpleapp.com/api/aws/download';

export const createAwsZip = (keyLists, fileName) => {
    return new Promise((resolve, reject) => {
        axios.post(downloadURL, {
            keys: keyLists,
            fileName
        }).then(res => resolve(true)).catch(err => reject(err))
    })
}