import React, { useEffect, useRef, useState } from "react";
import {
  ChonkyActions,
  defineFileAction,
  FileBrowser,
  FileNavbar,
  FileToolbar,
  FileList,
  FileContextMenu,
} from "chonky";
import { CustomIcon } from "./CustomIcon";
import Style from "./FileExplorer.module.css";
import { getRootFolderChain } from "./baseData";
import FolderUpdateModal from "./FolderUpdateModal";
import MoveFolderModal from "./MoveFolderModal";
import UploadFileModal from "../UploadFiles/UploadFileModal";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DownloadStartedModal from "./DownloadStartedModal";
import { startLoader, stopLoader } from "../../actions/loaderActions";
import EmptyFolderView from "./EmptyFolderView";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { setFileUploadPrefix } from "./../../actions/uploadFilesActions";
import Spinners from "../Spinners/Spinners";
import { s3, bucketName } from "../../constants/aws-s3";
import { getPrefixWithId } from "./commonFunctions";
import { getProjectDetailsWithId } from "../../actions/projectActions";
import ErrorPage from "./ErrorPage";
import { createAwsZip } from "./downloadFromAws";

export default function FileExplorer() {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [prefix, setPrefix] = useState("/");
  const [spinner, setSpinner] = useState(false);
  const [myFileActions, setMyFileActions] = useState([]);
  const [fileExplorerListView, setFileExplorerListView] = useState(true);
  const [folderChain, setFolderChain] = useState([]);
  const [folderUpdateModal, setFolderUpdateModal] = useState({
    show: false,
    title: "",
    type: "",
    isFile: false,
    existingName: "",
  });
  const [showMoveFolder, setShowMoveFolder] = useState(false);
  const [moveFolderModalProps, setMoveFolderModalProps] = useState({});
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [showDownloadFileModal, setShowDownloadFileModal] = useState(false);
  const [nothingToShow, setNothingToShow] = useState(false);
  const [bubbleDataForUser, setBubbleDataForUser] = useState({});
  const [user, setUser] = useState("Public");
  const currentUser = useSelector((state) => state.users.currentUser);
  const projectDetails = useSelector((state) => state.projectDetails);
  const errorUpdate = useSelector((state) => state.errorsUpdate);
  const selectedFilesToUpdate = useRef([]);
  const fileBrowserRef = React.useRef(null);

  let userType = "Public"; //Public || Admin

  useEffect(() => {
    const messageHandler = (event) => {
      if (event.data.dataFromBubble) {
        const dataFromBubble = event.data.dataFromBubble;
        setBubbleDataForUser(dataFromBubble);
      }
    };

    // Add event listener when the component mounts
    window.addEventListener("message", messageHandler);
    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(bubbleDataForUser).length > 0) {
      const _projectId =
        bubbleDataForUser.projectId && bubbleDataForUser.projectId !== ""
          ? bubbleDataForUser.projectId
          : bubbleDataForUser.sharedProjectID ?? "";

      if (_projectId !== "") {
        localStorage.setItem("projectId", _projectId);
        if (localStorage.getItem("projectId") == null) {
          toast.error("Something went wrong. Please refresh the page!");
          setNothingToShow(true);
        }

        dispatch(getProjectDetailsWithId(_projectId));
        setNothingToShow(false);
      } else {
        setNothingToShow(true);
      }
    }
  }, [bubbleDataForUser]);

  useEffect(() => {
    if (Object.keys(projectDetails).length > 0) {
      if (
        projectDetails.Users &&
        projectDetails.Users.length > 0 &&
        projectDetails.Users.includes(bubbleDataForUser.userId)
      ) {
        userType = "Admin";
        setUser("Admin");
      }
      startFileExplorer();
    }
  }, [projectDetails]);

  useEffect(() => {
    if (errorUpdate) {
      toast.error("Something went wrong.");
      setNothingToShow(true);
    }
  }, [errorUpdate]);

  const startFileExplorer = () => {
    setMyFileActionsForUserType();
    setDefaultFileAndFolder();
  };

  const move_folder = defineFileAction({
    id: "move_folder",
    requiresSelection: true,
    button: {
      name: "Move",
      toolbar: true,
      contextMenu: true,
      icon: "move",
      group: "Actions",
    },
  });
  const rename_folder = defineFileAction({
    id: "rename_folder",
    requiresSelection: true,
    button: {
      name: "Rename",
      toolbar: true,
      contextMenu: true,
      icon: "rename",
      group: "Actions",
    },
  });
  const download_folder = defineFileAction({
    id: "download_folder",
    requiresSelection: true,
    button: {
      name: "Download",
      toolbar: true,
      contextMenu: true,
      icon: "download",
    },
  });

  const setMyFileActionsForUserType = () => {
    const _myFileActions = [];

    const publicActions = [download_folder];

    const adminActions = [
      ChonkyActions.DownloadFiles,
      ChonkyActions.UploadFiles,
      ChonkyActions.CreateFolder,
      ChonkyActions.DeleteFiles,
      move_folder,
      rename_folder,
    ];
    if (userType === "Admin") {
      _myFileActions.push(...adminActions);
    } else {
      _myFileActions.push(...publicActions);
    }

    setMyFileActions([..._myFileActions]);
  };

  const actionsToDisable = [
    ChonkyActions.EnableGridView.id,
    ChonkyActions.OpenSelection.id,
    ChonkyActions.SortFilesBySize.id,
    ChonkyActions.SortFilesByDate.id,
    ChonkyActions.ToggleHiddenFiles.id,
  ];
  const handleFileAction = (actions) => {
    if (
      actions.id === "mouse_click_file" &&
      actions.payload.clickType === "single"
    ) {
      selectFileOnSingleClick(
        actions.state.selectedFiles,
        actions.payload.file
      );
    } else if (
      actions.id === "mouse_click_file" &&
      actions.payload.clickType === "double"
    ) {
      clearSelection(actions.state.selectedFiles);
      setTimeout(() => {
        onFolderDoubleCLick(actions.payload.file);
      }, 100);
    } else if (actions.id === "open_files") {
      setTimeout(() => {
        goToOpenFiles(actions.payload.targetFile);
      }, 100);
    } else if (actions.id === "create_folder") {
      onCreateFolder();
    } else if (actions.id === "rename_folder") {
      onRenameFolder(actions);
    } else if (actions.id === "move_folder") {
      onMoveFolder(actions);
    } else if (actions.id === "delete_files") {
      confirmDelete(actions);
    } else if (actions.id === "upload_files") {
      onUploadFile(actions);
    } else if (
      actions.id === "download_files" ||
      actions.id === "download_folder"
    ) {
      onDownloadFile(actions);
    } else if (actions.id === "enable_list_view") {
      setFileExplorerListView(true);
    } else if (actions.id === "enable_grid_view") {
      setFileExplorerListView(false);
    }
  };

  const clearSelection = React.useCallback(
    (selectedFiles) => {
      if (!fileBrowserRef.current) return;
      if (selectedFiles.length === 0) return null;
      fileBrowserRef.current.setFileSelection(new Set());
    },
    [files]
  );

  const selectFileOnSingleClick = React.useCallback(
    (selectedFiles, currentSelect) => {
      if (!fileBrowserRef.current) return;
      const newSelection = new Set();
      selectedFiles.forEach((file) => newSelection.add(file.id));
      if (newSelection.has(currentSelect.id))
        newSelection.delete(currentSelect.id);
      else newSelection.add(currentSelect.id);

      fileBrowserRef.current.setFileSelection(newSelection);
    },
    [files]
  );

  const setDefaultFileAndFolder = () => {
    setPrefix("/");
    setSpinner(true);
    getAwsData("/");
    setFolderChain(getRootFolderChain(projectDetails._name));
  };
  const onFolderDoubleCLick = (file) => {
    if (file.isDir) {
      setPrefix(file.id);
      setSpinner(true);
      getAwsData(file.id);
      createFolderChain(file);
    }
  };
  const createFolderChain = (file) => {
    setFolderChain([...folderChain, file]);
  };

  const goToOpenFiles = (targetFile) => {
    if (!targetFile.isDir) {
      return;
    }
    if (targetFile.id === "root_folder") {
      setDefaultFileAndFolder();
    } else {
      setPrefix(targetFile.id);
      setSpinner(true);
      getAwsData(targetFile.id);
      let positionIndex = null;
      folderChain.forEach((file, index) => {
        if (file.id === targetFile.id) {
          positionIndex = index;
        }
      });
      if (positionIndex !== null) {
        let folderChainCopy = [...folderChain];
        folderChainCopy.splice(positionIndex + 1);
        setFolderChain(folderChainCopy);
      }
    }
  };
  const onCreateFolder = () => {
    setFolderUpdateModal({
      ...folderUpdateModal,
      show: true,
      title: "Create New Folder",
      type: "createFolder",
      existingName: "",
      isFile: false,
    });
  };
  const onRenameFolder = (actions) => {
    if (actions.state.selectedFiles.length > 1) {
      toast.error("Cannot rename multiple files at a time.");
      return;
    }
    selectedFilesToUpdate.current = actions.state.selectedFiles;
    setFolderUpdateModal({
      ...folderUpdateModal,
      show: true,
      title: actions.state.selectedFiles[0].isDir
        ? "Rename Folder"
        : "Rename File",
      isFile: actions.state.selectedFiles[0].isDir ? false : true,
      type: "renameFolder",
      existingName: actions.state.selectedFiles[0].name,
    });
  };
  const onMoveFolder = (actions) => {
    setShowMoveFolder(true);
    setMoveFolderModalProps({ actions });
  };
  const onDeleteFile = async (actions) => {
    dispatch(startLoader());
    const deleteObject = await getAllObjectsInFolderAndSubFolder(actions);
    deleteAwsObject(deleteObject);
  };
  const confirmDelete = (actions) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteFile(actions);
      }
    });
  };
  const onUploadFile = (actions) => {
    setShowUploadFileModal(true);
    dispatch(setFileUploadPrefix(prefix));
  };

  const onDownloadFile = async (actions) => {
    console.log("download called", actions);
    Swal.fire({
      title: "Preparing Files For Download",
      text: "Your download will begin shortly.",
      icon: "success",
    });
    const responseObject = await getAllObjectsInFolderAndSubFolder(actions);
    downloadObject(responseObject);
  };

  const createFolderSubmit = (name) => {
    let filterFolderChain = folderChain.filter((file, index) => index !== 0);
    // console.log(folderChain);
    const folderPath =
      filterFolderChain.length === 0
        ? ""
        : filterFolderChain[filterFolderChain.length - 1].id;
    const finalAwsPath = folderPath + name + "/";
    createFolderAws(finalAwsPath, name);
  };

  const renameFolderSubmit = (name) => {
    setSpinner(true);
    renameAwsObject(selectedFilesToUpdate.current[0].id, name);
  };

  const createFolderAws = async (folderNameWithPath, folderName) => {
    try {
      await s3
        .putObject({
          // add user Id
          Key: `${getPrefixWithId(folderNameWithPath)}`, // This should create an empty object in which we can store files
          Bucket: bucketName,
          Metadata: {
            name: folderName,
            key: `${getPrefixWithId(folderNameWithPath)}`,
          },
        })
        .promise();
      toast.success("Folder created successfully");
      setSpinner(true);
      getAwsData(prefix);
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Folder cannot be created");
    }
  };

  const getAllObjectsInFolderAndSubFolder = async (actions) => {
    const filteredDir = actions.state.selectedFiles
      .filter((item) => {
        return item.isDir;
      })
      .map((item) => ({ Key: item.id }));
    const filteredFiles = actions.state.selectedFiles
      .filter((item) => {
        return !item.isDir;
      })
      .map((item) => ({ Key: item.id }));

    const getDirListFromAws = await listAwsObjects(filteredDir);
    const finalDirList = getDirListFromAws.map((item) => {
      return item.Contents;
    });
    const deleteKeyDir = finalDirList
      .flat(1)
      .map((item) => ({ Key: item.Key }));
    const deleteObject = [...filteredFiles, ...deleteKeyDir];
    return deleteObject;
  };

  const listAwsObjects = async (prefixList) => {
    try {
      const responseList = prefixList.map(async (element) => {
        return await s3
          .listObjects({
            Bucket: bucketName,
            Prefix: element.Key,
          })
          .promise();
      });
      return Promise.all(responseList);
    } catch (error) {
      console.error("Error in listObjects:", error);
    }
  };

  const deleteAwsObject = async (deleteObjectList, isMoveOperation = false) => {
    try {
      await s3
        .deleteObjects({
          Delete: {
            Objects: deleteObjectList,
          },
          Bucket: bucketName,
        })
        .promise();
      dispatch(stopLoader());
      setSpinner(true);
      getAwsData(prefix);
      if (isMoveOperation) {
        toast.success("Files moved successfully");
        return true;
      } else {
        toast.success("Deleted successfully");
      }
    } catch (error) {
      console.error("Error deleting file:", error);
      dispatch(stopLoader());
      if (isMoveOperation) {
        toast.error("Move operation failed");
        return false;
      } else {
        toast.error("Delete operation failed");
      }
    }
  };

  const renameAwsObject = async (key, newName) => {
    try {
      await s3
        .copyObject({
          Key: key, //  new key
          Bucket: bucketName,
          CopySource: bucketName + "/" + key, //`${BUCKET_NAME}/${file.Key}`,
          MetadataDirective: "REPLACE",
          Metadata: {
            name: newName,
            key,
          },
        })
        .promise();
      toast.success("Rename successfully");
      getAwsData(prefix);
    } catch (error) {
      console.error("Rename operation failed:", error);
      toast.error("Rename operation failed");
      setSpinner(false);
    }
  };

  function checkIfFile(str) {
    let isEmpty = str.split("/").pop();
    return isEmpty === "" ? false : true;
  }

  function getAwsMetaDataNameForFiles(Key, headerObjectDataList) {
    let filteredData = headerObjectDataList.filter(
      (item) => item.Metadata.key === Key
    );
    return filteredData.length === 0 ? "" : filteredData[0].Metadata?.name;
  }

  function getAwsMetaDataNameForFolders(prefix, headerObjectDataList) {
    let filteredData = headerObjectDataList.filter(
      (item) => item.Metadata.key === prefix
    );
    return filteredData.length === 0 ? "" : filteredData[0].Metadata?.name;
  }

  const getAwsHeaderObject = async (objectList) => {
    const objectPromiseList = [];
    objectList.forEach((item) => {
      const getMetadata = async (item) => {
        const params = {
          Bucket: bucketName, // bucket name
          Key: item, // file name
        };
        return await s3.headObject(params).promise();
      };
      const Metadata = getMetadata(item); //key_name of s3 file
      objectPromiseList.push(Metadata);
    });
    return Promise.all(objectPromiseList);
  };

  const getAwsData = (prefix) => {
    return s3
      .listObjectsV2({
        Bucket: bucketName,
        Delimiter: "/",
        Prefix: getPrefixWithId(prefix),
      })
      .promise()
      .then(async (response) => {
        const chonkyFiles = [];
        const s3Objects = response.Contents;
        const s3Prefixes = response.CommonPrefixes;

        const s3ObjectsKeys = s3Objects.map((item) => item.Key);
        const s3PrefixesKeys = s3Prefixes.map((item) => item.Prefix);
        const s3ObjectsHeadersList = await getAwsHeaderObject([
          ...s3PrefixesKeys,
          ...s3ObjectsKeys,
        ]);

        if (s3Objects) {
          let s3ObjectsFiltered = s3Objects.filter((file) =>
            checkIfFile(file.Key)
          );

          chonkyFiles.push(
            ...s3ObjectsFiltered.map((object) => {
              return {
                // add user Id
                id: getPrefixWithId(object.Key),
                name: getAwsMetaDataNameForFiles(
                  object.Key,
                  s3ObjectsHeadersList
                ),
                modDate: object.LastModified,
                size: object.Size,
                isDir: false,
              };
            })
          );
        }

        if (s3Prefixes) {
          chonkyFiles.push(
            ...s3Prefixes.map((prefix) => ({
              // add user Id
              id: getPrefixWithId(prefix.Prefix),
              name: getAwsMetaDataNameForFolders(
                prefix.Prefix,
                s3ObjectsHeadersList
              ),
              isDir: true,
              color: "var(--yellow-500)",
            }))
          );
        }
        setSpinner(false);
        setFiles(chonkyFiles);
      })
      .catch((err) => {
        setSpinner(false);
        toast.error("Cannot get data");
        console.log("get aws data err", err);
      });
  };

  function downloadCreate(fileNameKey, callback) {
    const interval = setInterval(function () {
      const params = {
        Bucket: bucketName, // bucket name
        Key: fileNameKey, // file name
      };
      s3.headObject(params)
        .promise()
        .then(async (res) => {
          clearInterval(interval);
          return callback(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 1500);
  }

  const downloadObject = async (keyObj) => {
    try {
      const keyList = keyObj.map((item) => item.Key);
      const uniqId = Date.now() + ".zip";
      let fileNameKey = getPrefixWithId(uniqId);
      const res = await createAwsZip(keyList, fileNameKey);
      if (res) {
        downloadCreate(fileNameKey, async (val) => {
          if (val) {
            const params = {
              Bucket: bucketName, // bucket name
              Key: fileNameKey, // file name
            };
            const data = await s3.getObject(params).promise();

            let blob = new Blob([data.Body], { type: data.ContentType });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "Project Files.zip";
            link.click();
            await s3
              .deleteObjects({
                Delete: {
                  Objects: [{ Key: fileNameKey }],
                },
                Bucket: bucketName,
              })
              .promise();
            toast.success("Download successful");
          }
        });
      }
    } catch (error) {
      toast.error("Download failed");
      console.log(error);
    }
  };

  return (
    <div className={Style.container}>
      {nothingToShow ? (
        <ErrorPage />
      ) : (
        <FileBrowser
          files={files}
          folderChain={folderChain}
          onFileAction={handleFileAction}
          fileActions={myFileActions}
          disableDefaultFileActions={actionsToDisable}
          iconComponent={CustomIcon}
          disableDragAndDrop={true}
          ref={fileBrowserRef}
          defaultFileViewActionId={ChonkyActions.EnableListView.id}
        >
          <FileNavbar />
          <div className="my-3">
            <FileToolbar />
          </div>
          <div className={Style.spinnerContainer}>
            {spinner ? (
              <Spinners />
            ) : (
              <>
                {files.length === 0 ? (
                  <EmptyFolderView userType={user} />
                ) : (
                  <div className={"fileListContainer"}>
                    <FileList />
                  </div>
                )}
              </>
            )}
          </div>
          <FileContextMenu />
        </FileBrowser>
      )}
      {/* --------------- Modals ----------------- */}
      <FolderUpdateModal
        folderUpdateModal={folderUpdateModal}
        setFolderUpdateModal={setFolderUpdateModal}
        createFolderSubmit={createFolderSubmit}
        renameFolderSubmit={renameFolderSubmit}
      />
      <MoveFolderModal
        showMoveFolder={showMoveFolder}
        setShowMoveFolder={setShowMoveFolder}
        moveFolderModalProps={moveFolderModalProps}
        parentFolderPrefix={prefix}
        deleteAwsObject={deleteAwsObject}
        getAllObjectsInFolderAndSubFolder={getAllObjectsInFolderAndSubFolder}
        getAwsHeaderObject={getAwsHeaderObject}
        fileExplorerListView={fileExplorerListView}
        projectDetails={projectDetails}
      />
      <UploadFileModal
        show={showUploadFileModal}
        setShow={setShowUploadFileModal}
        getAwsData={getAwsData}
        setSpinner={setSpinner}
      />
      <DownloadStartedModal
        show={showDownloadFileModal}
        setShow={setShowDownloadFileModal}
      />
    </div>
  );
}
