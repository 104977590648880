import { GET_FOLDER_STRUCTURE } from "../constants/actionTypes";

const initialState = []

const fileExplorerReducers = (state = initialState, action) => {
    switch (action.type) {
        case GET_FOLDER_STRUCTURE:
            return [...action.payload]
        default:
            return state;
    }
};

export default fileExplorerReducers;