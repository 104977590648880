import React, { useEffect, useState } from "react";
import { CloudArrowUp, XLg } from "react-bootstrap-icons";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DragAndDrop from "../DragAndDrop/DragAndDrop";
import { useDispatch } from "react-redux";
import { setUploadedFilesList } from "../../actions/uploadedFilesListActions";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const UploadFileModal = ({
  show,
  setShow,
  onUploadFiles,
  getAwsData,
  setSpinner,
}) => {
  const dispatch = useDispatch();
  const prefixPath = useSelector((state) => state.fileUpload);
  const uploadedFilesList = useSelector((state) => state.fileListUploaded);
  const uploadingFileCounter = useSelector(
    (state) => state.uploadedFilesCounter
  );

  const confirmClose = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Closing modal will interrupt any upload in progress !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, close it!",
    }).then((result) => {
      if (result.isConfirmed) {
        closeModal();
      }
    });
  };

  const closeModal = () => {
    setShow(false);
    dispatch(setUploadedFilesList([]));
    setSpinner(true);
    getAwsData(prefixPath);
  };

  return (
    <div>
      <Modal isOpen={show} size="lg">
        <ModalHeader>Upload Files</ModalHeader>
        <ModalBody>
          <DragAndDrop onUploadFiles={onUploadFiles} />
        </ModalBody>
        <ModalFooter>
          {/* <Button color="primary" onClick={() => setStartUpload(true)}>
            <CloudArrowUp size={20} className="mb-1" /> Upload
          </Button> */}
          <Button
            color="secondary"
            onClick={() => {
              if (
                uploadedFilesList.length === 0 ||
                uploadingFileCounter === 0
              ) {
                closeModal();
              } else {
                confirmClose();
              }
            }}
          >
            <XLg className="mb-1" /> Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UploadFileModal;
