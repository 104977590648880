import React from "react";
import Style from "./FileExplorer.module.css";

const ErrorPage = () => {
  return (
    <div className={Style.errorPageContainer}>
      <span className="fs-2">Project Not Found!</span>
    </div>
  );
};

export default ErrorPage;
