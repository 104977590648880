import React, { memo } from "react";
import Style from "./DragAndDrop.module.css";
import EachFile from "./EachFile";
import { useSelector } from "react-redux";

const FilesTable = () => {
  const uploadedFilesList = useSelector((state) => state.fileListUploaded);

  return (
    <div className="mt-4">
      <h4 className="mb-3">Files</h4>
      <ul className={Style.filesTableUl}>
        {uploadedFilesList.length > 0 ? (
          uploadedFilesList.map((file) => (
            <EachFile key={file.fileId} file={file} />
          ))
        ) : (
          <label className="text-center w-100">No files uploaded</label>
        )}
      </ul>
    </div>
  );
};

export default memo(FilesTable);
