import { ERROR_UPDATE } from "../constants/actionTypes";

const initialState = null

const errorUpdateReducer = (state = initialState, action) => {
    switch (action.type) {
        case ERROR_UPDATE:
            return action.payload
        default:
            return state;
    }
};

export default errorUpdateReducer;