export const ACTIVATE_LOADER = 'ACTIVATE_LOADER'
export const DEACTIVATE_LOADER = 'DEACTIVATE_LOADER'
export const GET_FOLDER_STRUCTURE = 'FILE_EXPLORER_CREATE_FOLDER'
export const SET_FILE_UPLOAD_PREFIX = 'SET_FILE_UPLOAD_PREFIX'
export const SET_UPLOADED_FILE_LIST = 'SET_UPLOADED_FILE_LIST'
export const GET_CURRENT_USER = 'GET_CURRENT_USER'
export const USER_IS_ACTIVE = 'USER_IS_ACTIVE'
export const SET_COUNTER_UPLOADED_FILES = 'SET_COUNTER_UPLOADED_FILES'
export const REMOVE_COUNTER_UPLOADED_FILES = 'REMOVE_COUNTER_UPLOADED_FILES'
export const GET_PROJECT_DETAILS = 'GET_PROJECT_DETAILS'
export const ERROR_UPDATE = 'ERROR_UPDATE'