import React from "react";
import { Spinner } from "reactstrap";
import Style from "./Spinners.module.css";

const Spinners = () => {
  return (
    <div className={`${Style.spinnerContainer} `}>
      <Spinner color="dark">Loading...</Spinner>
    </div>
  );
};

export default Spinners;
