import { SET_COUNTER_UPLOADED_FILES, REMOVE_COUNTER_UPLOADED_FILES } from "../constants/actionTypes"

const initialState = 0

const uploadedFilesCountersReducers = (state = initialState, action) => {
    switch (action.type) {
        case SET_COUNTER_UPLOADED_FILES:
            return state + 1
        case REMOVE_COUNTER_UPLOADED_FILES:
            return state - 1
        default:
            return state;
    }
};

export default uploadedFilesCountersReducers;