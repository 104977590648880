import { SET_COUNTER_UPLOADED_FILES, REMOVE_COUNTER_UPLOADED_FILES } from "../constants/actionTypes"

export const setCounterForUploadedFiles = () => {
    return {
        type: SET_COUNTER_UPLOADED_FILES
    }
}
export const removeCounterForUploadedFiles = () => {
    return {
        type: REMOVE_COUNTER_UPLOADED_FILES
    }
}