import projectsApi from "../api/commonApiForProjects";
import { GET_PROJECT_DETAILS, } from "../constants/actionTypes";
import { startLoader, stopLoader } from "./loaderActions";
import { updateErrorStatus } from './errorActions';

const config = {
    headers: { Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}` }
};

export const getProjectDetailsWithId = (_id) => {
    return dispatch => {
        dispatch(startLoader())
        projectsApi.get(`/${_id}`, config)
            .then(res => {
                dispatch(stopLoader())
                dispatch({
                    type: GET_PROJECT_DETAILS,
                    payload: res.data.response
                });
            }).catch(error => {
                dispatch(stopLoader())
                dispatch(updateErrorStatus(error))
                console.log("Error", error);
            })
    };
};


